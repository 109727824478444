<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Статистика прочтений</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-list dense>
        <!-- <v-subheader inset> Кабинет </v-subheader> -->
        <v-list-item-group color="primary">
          
          <v-list-item
            v-if="currentUser && +currentUser.author"
            :to="{ name: 'CabinetStatisticsViewsPeriod' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.poll</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Просмотры</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="currentUser && +currentUser.author"
            :to="{ name: 'CabinetStatisticsViews' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.poll</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Просмотры (выбор даты)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="currentUser && +currentUser.author"
            :to="{ name: 'CabinetStatisticsViewsByChapters' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.poll</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Просмотры по главам</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({}),
  methods: {},
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      //blogs: "cabinet_blogs/blogs"
    }),
  },
  mounted() {},
};
</script>
